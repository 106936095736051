require('./bootstrap')

require('bootstrap-table/dist/bootstrap-table.js')
require('bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie.min.js')
require('bootstrap-table/dist/extensions/mobile/bootstrap-table-mobile.min.js')
require('select2')
require('jquery.cookie')

$(function () {
  setTimeout(function () {
    $('p.alert').remove()
  }, 10000)

  $('.select2').select2({})

  $('.bootstrap-table-default').bootstrapTable({
    formatSearch: function () {
      return 'Cercar'
    },
  })
})
